body {
  background-color: #173668;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Disable text selection. */
  -webkit-user-select: none;

  /* Disable highlighting. */
  -webkit-tap-highlight-color: transparent;

  /* Disable callouts. */
  -webkit-touch-callout: none;

  /* Smooth scrolling in iOS */
  overflow-y: scroll; /* has to be scroll, not auto */
  -webkit-overflow-scrolling: touch;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
